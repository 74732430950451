import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/Logo";
import FooterNav from "./partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";
import { Link } from "react-router-dom";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

class Footer extends React.Component {
  render() {
    const { className, topOuterDivider, topDivider, ...props } = this.props;

    const classes = classNames(
      "site-footer invert-color",
      topOuterDivider && "has-top-divider",
      className
    );

    return (
      <footer {...props} className={classes}>
        <div className="container">
          <div
            className={classNames(
              "site-footer-inner",
              topDivider && "has-top-divider"
            )}
          >
            <div className="footer-top text-xxs">
              <div className="footer-blocks">
                <div className="footer-block">
                  <Logo className="mb-16" />
                  <div className="footer-copyright">
                    &copy; 2021 CF Block Residents' Association
                  </div>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Subscriptions</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="https://pages.razorpay.com/pl_IC6kCqa6VTB8xs/view">
                        Donate
                      </a>
                    </li>
                    <li>
                      <a href="https://pages.razorpay.com/pl_IC7LXl6cJ1dYGs/view">
                        Puja Subscriptions
                      </a>
                    </li>
                    <li>
                      <Link to="/payments/">
                        Other Payments
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Membership</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="https://pages.razorpay.com/pl_IC6xyFe8vyawQW/view">
                        Annual Membership
                      </a>
                    </li>
                    <li>
                      <a href="https://pages.razorpay.com/pl_IC7BSjM62tP6yC/view">
                        Associate Membership
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Activities</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <Link to="/activities/8joZ0qbiuwFgpBewetQc">
                        DURGA PUJA
                      </Link>
                    </li>
                    <li>
                      <Link to="/activities/bacK9jnm4dMJd6uonrjB">
                        SARASWATI PUJA
                      </Link>
                    </li>
                    <li>
                      <Link to="/activities/enK30blg12FYoBH9SGKw">LIBRARY</Link>
                    </li>
                    <li>
                      <Link to="/activities/i4H93g6uJuiEhtcaWP3V">
                        COMMUNITY WELFARE
                      </Link>
                    </li>
                    <li>
                      <Link to="/activities/iv2PNMcdgqGrsWZFTmv8">
                        ANNUAL SPORTS
                      </Link>
                    </li>
                    <li>
                      <Link to="/activities/vSlOLPH5doC105138cw0">
                        CULTURAL ACTIVITY
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-bottom space-between center-content-mobile text-xxs">
              <FooterNav />
              <FooterSocial />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
