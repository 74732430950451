import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Button from "../elements/Button";
import Modal from "../elements/Modal";
import Image from "../elements/Image";
import Input from "../elements/Input";
import { saveDoc } from "../../utils/firebase";
import { MembershipContext } from "../../providers/MembershipProvider";

const propTypes = {
  ...SectionTilesProps.types,
  pricingSwitcher: PropTypes.bool,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSwitcher: false,
};

const Pricing = (props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    pricingSwitcher,
    ...rest
  } = props;

  const [state, setState] = useState({
    pricingSwitchOn: true,
    demoModalActive: false,
  });

  const [application, setApplication] = useState({
    name: "",
    email: "",
    membership: "",
    url: "",
  });

  const [uploading, setUploading] = useState(false);

  const {
    data: pageData,
    loading,
    addApplication,
  } = useContext(MembershipContext);

  const openModal = (e, membership) => {
    e.preventDefault();
    setApplication({
      name: "",
      email: "",
      url: "",
      membership,
    });
    setState({ ...state, demoModalActive: true });
  };

  const closeModal = (e) => {
    e.preventDefault();
    setState({ ...state, demoModalActive: false });
  };

  const handleChange = (e) => {
    setApplication({ ...application, [e.target.name]: e.target.value });
  };

  const handleUploadForm = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.hidden = true;

    input.onchange = async (e) => {
      const file = e.target.files[0];
      setUploading(true);
      const url = await saveDoc(file);
      setApplication({ ...application, url });
      setUploading(false);
    };

    input.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addApplication(
      application.url,
      application.email,
      application.name,
      application.membership
    );
    alert(
      "Your membership form has been submitted, you will be notified once the Governing Body approves your application. Thank you"
    );
    setState({ ...state, demoModalActive: false });
  };

  const outerClasses = classNames(
    "pricing section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "pricing-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap illustration-section-02",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "MEMBERSHIP",
    paragraph: pageData.description,
  };

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-top" data-reveal-delay="200">
              <div className="tiles-item-inner has-shadow">
                <div className="pricing-item-content">
                  <div className="pricing-item-header pb-16 mb-24">
                    <div className="pricing-item-price">
                      <span className="pricing-item-price-currency h3 text-color-mid">
                        ANNUAL MEMBERSHIP
                      </span>
                    </div>
                  </div>
                  <div className="pricing-item-features mb-40">
                    <div className="pricing-item-features-title text-xs text-color-high mb-24">
                      What's included
                    </div>
                    <ul className="pricing-item-features-list list-reset text-xs mb-32">
                      {pageData.form1Description
                        .split("\n")
                        .map((item, index) => (
                          <li className="is-checked" key={index}>
                            {item}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="pricing-item-cta mb-8">
                  <Button
                    color="dark"
                    wide
                    onClick={() => window.open(pageData.form1, "_blank")}
                  >
                    Download Form
                  </Button>
                  <Button
                    color="primary"
                    wide
                    aria-controls="demo-modal"
                    onClick={(e) => openModal(e, "Annual")}
                  >
                    Subscribe
                  </Button>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-top">
              <div className="tiles-item-inner has-shadow">
                <div className="pricing-item-content">
                  <div className="pricing-item-header pb-16 mb-24">
                    <div className="pricing-item-price">
                      <span className="pricing-item-price-currency h3 text-color-mid">
                        ASSOCIATE MEMBERSHIP
                      </span>
                    </div>
                  </div>
                  <div className="pricing-item-features mb-40">
                    <div className="pricing-item-features-title text-xs text-color-high mb-24">
                      What's included
                    </div>
                    <ul className="pricing-item-features-list list-reset text-xs mb-32">
                      {pageData.form2Description
                        .split("\n")
                        .map((item, index) => (
                          <li className="is-checked" key={index}>
                            {item}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="pricing-item-cta mb-8">
                  <Button
                    color="dark"
                    wide
                    onClick={() => window.open(pageData.form2, "_blank")}
                  >
                    Download Form
                  </Button>
                  <Button
                    color="primary"
                    wide
                    aria-controls="demo-modal"
                    onClick={(e) => openModal(e, "Associate")}
                  >
                    Subscribe
                  </Button>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-top" data-reveal-delay="200">
              <div className="tiles-item-inner has-shadow">
                <div className="pricing-item-content">
                  <div className="pricing-item-header pb-16 mb-24">
                    <div className="pricing-item-price">
                      <span className="pricing-item-price-currency h3 text-color-mid">
                        LIFE TIME MEMBERSHIP
                      </span>
                    </div>
                  </div>
                  <div className="pricing-item-features mb-40">
                    <div className="pricing-item-features-title text-xs text-color-high mb-24">
                      What's included
                    </div>
                    <ul className="pricing-item-features-list list-reset text-xs mb-32">
                      {pageData.form3Description
                        .split("\n")
                        .map((item, index) => (
                          <li className="is-checked" key={index}>
                            {item}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="pricing-item-cta mb-8">
                  <Button
                    color="dark"
                    wide
                    onClick={() => window.open(pageData.form3, "_blank")}
                  >
                    Download Form
                  </Button>
                  <Button
                    color="primary"
                    wide
                    aria-controls="demo-modal"
                    onClick={(e) => openModal(e, "Lifetime")}
                  >
                    Subscribe
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        id="demo-modal"
        show={state.demoModalActive}
        handleClose={closeModal}
      >
        <div className="center-content">
          <div className="features-split-icon mb-16">
            <Image
              src={require("../../assets/images/logo.jpeg")}
              alt="Frame icon"
              width={64}
              height={64}
            />
          </div>
          <h3 className="mt-0 mb-12">
            Request for The {application.membership} Membership
          </h3>
          <p className="text-sm">
            BE THE PART OF #BEST ASSOCIATION IN SALT LAKE
          </p>
        </div>
        <Input
          type="text"
          label="Name"
          name="name"
          placeholder="Your full name.."
          value={application.name}
          onChange={handleChange}
          formGroup="desktop"
          labelHidden
        />
        <Input
          type="email"
          label="Email"
          name="email"
          placeholder="Your best email.."
          value={application.email}
          onChange={handleChange}
          formGroup="desktop"
          labelHidden
        />
        <div className="mt-16 mb-16">
          <Button
            color="dark"
            wide
            loading={uploading}
            onClick={handleUploadForm}
          >
            Upload Form
          </Button>
        </div>
        <Button wide color="primary" loading={loading} onClick={handleSubmit}>
          Submit
        </Button>
        <div className="center-content mt-24">
          <a
            className="func-link text-xxs fw-500 tt-u"
            aria-label="close"
            href="#0"
            onClick={closeModal}
          >
            No thanks!
          </a>
        </div>
      </Modal>
    </section>
  );
};

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
