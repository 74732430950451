import React from "react";
import About from "../components/sections/About";
import Cta from "../components/sections/Cta";
import HeroFull from "../components/sections/HeroFull";
import Team from "../components/sections/Team";
import AboutProvider from "../providers/AboutProvider";

const AboutUs = () => {
  return (
    <AboutProvider>
      <HeroFull className="illustration-section-01" />
      <Team className="illustration-section-04" />
      <About />
      <Cta hasBgColor invertColor split className="illustration-element-08" />
    </AboutProvider>
  );
};

export default AboutUs;
