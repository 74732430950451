import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { AboutContext } from '../../providers/AboutProvider';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Team = (props) => {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...rest
    } = props;

    const {about} = React.useContext(AboutContext);

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'team-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'OUR TEAM',
      paragraph: ''
    };

    return (
      <section
        {...rest}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-top" />
            <div className={tilesClasses}>

              {about.members.map((member, index) => <div className="tiles-item">
                <div key={index} className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24 illustration-element-07">
                      <Image
                        src={member.image}
                        alt="Team member 01"
                        width={190}
                        height={190} />
                    </div>
                  </div>
                  <div className="team-item-content">
                      <h5 className="team-item-name mt-0 mb-4">
                          {member.name}
                      </h5>
                      <div className="team-item-role text-xxs tt-u text-color-primary mb-8">
                          {member.role}
                      </div>
                      {/* <p className="m-0 text-sm">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
                      </p> */}
                  </div>
                </div>
              </div>)}

            </div>
          </div>
        </div>
      </section>
    );
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;