import React from "react";
import { AboutContext } from "../../providers/AboutProvider";
import Button from "../elements/Button";
import Image from "../elements/Image";
import GenericSection from "./GenericSection";

const About = () => {
  const { about } = React.useContext(AboutContext);

  return (
    <GenericSection topDivider>
      <div className="container-xs">
        <h2 className="mt-0">{about.title}</h2>
        <p>{about.description}</p>
        <figure>
          <Image
            className="image-larger"
            src={about.image}
            alt="Placeholder"
            width={712}
            height={400}
          />
          <figcaption className="text-color-low">
            A super-nice image{" "}
            <span role="img" aria-label="smile">
              😀
            </span>
          </figcaption>
        </figure>
        <h4>What We Do</h4>
        <ul>
          <li>Social Activity & Social Awareness</li>
          <li>Community Welfare</li>
          <li>Durga Puja, Saraswati Puja</li>
          <li>and many more Cultural & Spports Activities</li>
        </ul>
        <div className="button-group">
          <a
            className="button button-primary button-wide-mobile"
            href={about.download}
            download
            target="_blank"
          >
            Get List of Members
          </a>
          <a
            className="button button-secondary button-wide-mobile"
            href={about.download2}
            download
            target="_blank"
          >
            Governing Body Members
          </a>
        </div>
      </div>
    </GenericSection>
  );
};

export default About;
