import React from "react";
import { savePhoto } from "../utils/firebase";
import GenericSection from "../components/sections/GenericSection";
import Button from "../components/elements/Button";
import Input from "../components/elements/Input";
import ActivityProvider, {
  ActivityContext,
} from "../providers/ActivityProvider";

const EditActivityChild = ({ id }) => {
  const { activity, updateActivity, loading } =
    React.useContext(ActivityContext);

  const [state, setState] = React.useState({
    title: "",
    description: "",
    image: "",
  });

  React.useEffect(() => {
    setState(activity);
  }, [activity]);

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  const handleLeadImage = async (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      const url = await savePhoto(e.target.files[0]);
      setState({ ...state, image: url });
    }
  };

  return (
    <React.Fragment>
      <GenericSection bottomDivider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Edit Activity</h1>
          <Button
            color={"primary"}
            loading={loading}
            onClick={() => updateActivity(state, id)}
          >
            Save Changes
          </Button>
        </div>
      </GenericSection>
      <GenericSection>
        <div className="container-xs">
          <Input
            type="text"
            label="Title"
            className="mb-16"
            value={state.title}
            onChange={(e) => setState({ ...state, title: e.target.value })}
          />
          <Input
            type="textarea"
            label="Description"
            className="mb-16"
            rows={10}
            value={state.description}
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
          />
          {!!state.image ? (
            <React.Fragment>
              <h6>Image</h6>
              <img src={state.image} />
              <div className="mt-24 center-content">
                <Button
                  color={"secondary"}
                  size={"sm"}
                  onClick={(e) => setState({ ...state, image: "" })}
                >
                  Remove
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <Input type="file" label="Image" onChange={handleLeadImage} />
          )}
        </div>
      </GenericSection>
    </React.Fragment>
  );
};

const EditActivity = (props) => {
  const id = props.match.params.id;
  return (
    <ActivityProvider id={id}>
      <EditActivityChild id={id} />
    </ActivityProvider>
  );
};

export default EditActivity;
