import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Button from "../elements/Button";
import { PaymentContext } from "../../providers/PaymentProvider";

const propTypes = {
  ...SectionTilesProps.types,
  pricingSwitcher: PropTypes.bool,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSwitcher: false,
};

const PaymentsChild = (props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...rest
  } = props;

  const { page, paymentLinks } = useContext(PaymentContext);

  const outerClasses = classNames(
    "pricing section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "pricing-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "PAYMENTS",
    paragraph: page.description,
  };

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={tilesClasses}>
            {paymentLinks.map((pLink) => (
              <div className="tiles-item">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-16 mb-24">
                      <div className="pricing-item-price">
                        <span className="pricing-item-price-currency h3 text-color-mid">
                          {pLink.title}
                        </span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title text-xs text-color-high mb-24">
                        {pLink.description}
                      </div>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button
                      color="primary"
                      wide
                      aria-controls="demo-modal"
                      onClick={(e) => window.open(pLink.link, "_blank")}
                    >
                      Pay
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

PaymentsChild.propTypes = propTypes;
PaymentsChild.defaultProps = defaultProps;

export default PaymentsChild;
