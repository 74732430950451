import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../utils/SectionProps";
import SectionHeader from "../components/sections/partials/SectionHeader";
import Image from "../components/elements/Image";
import Button from "../components/elements/Button";
import ActivitiesProvider, {
  ActivitiesContext,
} from "../providers/ActivitiesProvider";
import Modal from "../components/elements/Modal";
import Input from "../components/elements/Input";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const UpdateActivities = (props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...rest
  } = props;

  const outerClasses = classNames(
    "team section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "team-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Update Activities",
    paragraph: "Add or remove activities",
  };
  const [demoModalActive, setDemoModalActive] = React.useState(false);
  const [formState, setFormState] = React.useState({
    title: "",
    description: "",
    image: null,
  });

  const { activities, addActivity, removeActivity, loading } =
    React.useContext(ActivitiesContext);

  const openModal = () => {
    setDemoModalActive(true);
  };

  const closeModal = () => {
    setDemoModalActive(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await addActivity(formState);
    setFormState({
      title: "",
      description: "",
      image: null,
    });
    closeModal();
  };

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {activities.map((activity, index) => (
              <div key={index} className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-16">
                      <Image
                        src={activity.image}
                        alt="Features tile icon 01"
                        width={220}
                        height={220}
                      />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h6 className="team-item-name mt-0 mb-4">
                      {activity.title}
                    </h6>
                    <div className="team-item-role text-xxs tt-u text-color-primary mb-8">
                      <Button
                        size={"sm"}
                        loading={loading}
                        onClick={() => removeActivity(activity.id)}
                      >
                        Remove
                      </Button>
                      <Link
                        to={`/edit-activities/${activity.id}`}
                        className="func-link text-xxs fw-500 tt-u"
                      >
                        Edit
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="center-content">
            <Button
              color={"primary"}
              aria-controls="demo-modal"
              onClick={openModal}
            >
              Add New Activity
            </Button>
          </div>
        </div>
      </div>

      <Modal id="demo-modal" show={demoModalActive} handleClose={closeModal}>
        <Input
          type="file"
          label="Image"
          formGroup="desktop"
          labelHidden={false}
          onChange={(e) =>
            setFormState({ ...formState, image: e.target.files[0] })
          }
        />
        <Input
          type="text"
          label="Title"
          placeholder="Title here.."
          formGroup="desktop"
          labelHidden={false}
          onChange={(e) =>
            setFormState({ ...formState, title: e.target.value })
          }
        />
        <Input
          type="text"
          label="Description"
          placeholder="Descrion here.."
          formGroup="desktop"
          labelHidden={false}
          onChange={(e) =>
            setFormState({ ...formState, description: e.target.value })
          }
        />
        <div className="center-content mt-24">
          <Button color="primary" loading={loading} onClick={handleSave}>
            Add
          </Button>
          <a
            className="func-link text-xxs fw-500 tt-u ml-24"
            aria-label="close"
            href="#0"
            onClick={closeModal}
          >
            Cancel
          </a>
        </div>
      </Modal>
    </section>
  );
};

UpdateActivities.propTypes = propTypes;
UpdateActivities.defaultProps = defaultProps;

const EditActivities = () => {
  return (
    <ActivitiesProvider>
      <UpdateActivities />
    </ActivitiesProvider>
  );
};

export default EditActivities;
