import React from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";

const AdminRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { user } = React.useContext(AuthContext);

  Layout =
    Layout === undefined
      ? (props) => <React.Fragment>{props.children}</React.Fragment>
      : Layout;

  return !!user ? (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  ) : (
    <Route {...rest} render={() => <Redirect to="/login" />} />
  );
};

export default AdminRoute;
