import React, { useContext, useEffect } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import { Link } from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Input from "../elements/Input";
import Button from "../elements/Button";
import Checkbox from "../elements/Checkbox";
import { AuthContext } from "../../providers/AuthProvider";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const LoginForm = (props) => {
  const [formState, setFormState] = React.useState({
    email: "",
    password: "",
  });

  const { login, loading, error, resolve } = useContext(AuthContext);

  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...rest
  } = props;

  const outerClasses = classNames(
    "signin section",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "signin-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  useEffect(() => {
    if (error) {
      alert(error);
      resolve();
    }
  }, [error]);

  const sectionHeader = {
    title: "Welcome back. We exist to make entrepreneurship easier.",
  };

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login(formState.email, formState.password);
  };

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            tag="h1"
            data={sectionHeader}
            className="center-content"
          />
          <div className="tiles-wrap">
            <div className="tiles-item">
              <div className="tiles-item-inner">
                <form onSubmit={handleSubmit}>
                  <fieldset>
                    <div className="mb-12">
                      <Input
                        type="email"
                        label="Email"
                        placeholder="Email"
                        name="email"
                        value={formState.email}
                        onChange={handleChange}
                        labelHidden
                        required
                      />
                    </div>
                    <div className="mb-12">
                      <Input
                        type="password"
                        label="Password"
                        placeholder="Password"
                        name="password"
                        value={formState.password}
                        onChange={handleChange}
                        labelHidden
                        required
                      />
                    </div>
                    <div className="mt-24 mb-32">
                      <Button type="submit" color="primary" wide loading={loading}>
                        Sign in
                      </Button>
                    </div>
                    <div className="signin-footer mb-32">
                      <Checkbox>Remember me</Checkbox>
                      <Link
                        to="/recover-password/"
                        className="func-link text-xs"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </fieldset>
                </form>
                <div className="signin-bottom has-top-divider">
                  <div className="pt-32 text-xs center-content text-color-low">
                    Don't you have an account?{" "}
                    <Link to="/signup/" className="func-link">
                      Sign up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
