import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from "../components/elements/Button";
import Input from "../components/elements/Input";
import Modal from "../components/elements/Modal";
import GenericSection from "../components/sections/GenericSection";
import NoticesProvider, { NoticesContext } from "../providers/NoticesProvider";

const NoticeBoardTable = () => {
  const { notices, addNotice, removeNotice, loading } =
    useContext(NoticesContext);

  const [demoModalActive, setDemoModalActive] = React.useState(false);
  const [formState, setFormState] = React.useState({
    subject: "",
    description: "",
    attachment: null,
    expireOn: null,
  });
  const history = useHistory();

  const openModal = () => {
    setDemoModalActive(true);
  };

  const closeModal = () => {
    setDemoModalActive(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await addNotice({ ...formState, createdOn: new Date() });
    setFormState({
      subject: "",
      description: "",
      attachment: null,
      expireOn: null,
    });
    closeModal();
  };

  return (
    <React.Fragment>
      <div className="p-24 center-content">
        <Button onClick={openModal} color={"primary"}>
          Add Notice
        </Button>
      </div>
      <div className="table-responsive">
        {notices.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>IDX</th>
                <th>Subject</th>
                <th>Expires On</th>
                <th>Created On</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {notices.map((notice, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{notice.subject}</td>
                  <td>{notice.expireOn?.toDate()?.toLocaleDateString()}</td>
                  <td>{notice.createdOn?.toDate()?.toLocaleDateString()}</td>
                  <td>
                    <Button
                      color="primary"
                      onClick={() => {
                        history.push(`/edit-notices/${notice.id}`);
                      }}
                    >
                      View
                    </Button>
                    <Button
                      color="danger"
                      loading={loading}
                      onClick={() => {
                        removeNotice(notice.id);
                      }}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {notices.length === 0 && (
          <div className="p-24 center-content">No current notice</div>
        )}
      </div>

      <Modal id="demo-modal" show={demoModalActive} handleClose={closeModal}>
        <Input
          type="text"
          label="Subject"
          placeholder="Subject here.."
          formGroup="desktop"
          labelHidden={false}
          onChange={(e) =>
            setFormState({ ...formState, subject: e.target.value })
          }
        />
        <Input
          type="textarea"
          label="Description"
          placeholder="Descrion here.."
          formGroup="desktop"
          labelHidden={false}
          onChange={(e) =>
            setFormState({ ...formState, description: e.target.value })
          }
        />
        <Input
          type="date"
          label="Expires On"
          formGroup="desktop"
          labelHidden={false}
          onChange={(e) =>
            setFormState({ ...formState, expireOn: new Date(e.target.value) })
          }
        />
        <Input
          type="file"
          label="Attachment"
          formGroup="desktop"
          labelHidden={false}
          onChange={(e) =>
            setFormState({ ...formState, attachment: e.target.files[0] })
          }
        />
        <div className="center-content mt-24">
          <Button color="primary" loading={loading} onClick={handleSave}>
            Add
          </Button>
          <a
            className="func-link text-xxs fw-500 tt-u ml-24"
            aria-label="close"
            href="#0"
            onClick={closeModal}
          >
            Cancel
          </a>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const EditNoticeBoard = () => {
  return (
    <NoticesProvider>
      <GenericSection>
        <div className="container-sm">
          <h1 className="center-content">Edit Notice Board</h1>
          <NoticeBoardTable />
        </div>
      </GenericSection>
    </NoticesProvider>
  );
};

export default EditNoticeBoard;
