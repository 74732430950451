import React from "react";
import PropTypes from "prop-types";
import { db } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore/lite";

export const NoticeContext = React.createContext();

const NoticeProvider = (props) => {
    const [notice, setNotice] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const noticeRef = doc(db, "notices", props.id);
        getDoc(noticeRef).then((doc) => {
            setNotice(doc.data());
        });
    }, []);

    const updateNotice = (notice, id) => {
        const ref = doc(db, "notices", id);
        setLoading(true);
        setDoc(ref, notice)
            .then(() => setNotice(notice))
            .finally(() => setLoading(false));
    }

    const value = {
        notice,
        updateNotice,
        loading,
    };

    return (
        <NoticeContext.Provider value={value}>
            {props.children}
        </NoticeContext.Provider>
    );
}

NoticeProvider.propTypes = {
    id: PropTypes.string,
};

export default NoticeProvider;