import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage, db } from "../config/firebase";
import * as uuid from "uuid";
import { addDoc, collection } from "firebase/firestore/lite";

/**
 *
 * @param {Blob} file
 * @returns {Promise<string>}
 */
export const savePhoto = async (file) => {
  const imgRef = ref(storage, "images/" + uuid.v4());
  await uploadBytes(imgRef, file);
  return getDownloadURL(imgRef);
};

/**
 *
 * @param {Blob} file
 * @returns {Promise<string>}
 */
export const saveVideo = async (file) => {
  const imgRef = ref(storage, "videos/" + uuid.v4());
  await uploadBytes(imgRef, file);
  return getDownloadURL(imgRef);
};

/**
 *
 * @param {Blob} file
 * @returns {Promise<string>}
 */
export const saveDoc = async (file) => {
  const imgRef = ref(storage, "docs/" + uuid.v4());
  await uploadBytes(imgRef, file);
  return getDownloadURL(imgRef);
};

/**
 * 
 * @param {{
 * name: string,
 * email: string,
 * phone: string,
 * address: string,
 * message: string
 * }} info 
 */
export const submitContact = async (info) => {
  const ref = collection(db, "contacts");
  await addDoc(ref, info);
};

/**
 * 
 * @param {{
 * name: string,
 * email: string,
 * phone: string,
 * plot: string,
 * }} info 
 */
export const reqestLifetimeMembership = async (info) => {
  const ref = collection(db, "lifetimeMembership");
  await addDoc(ref, info);
};
