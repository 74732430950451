import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore/lite";
import React from "react";
import { db } from "../config/firebase";
import { saveDoc } from "../utils/firebase";

export const NoticesContext = React.createContext();

const NoticesProvider = (props) => {
  const [notices, setNotices] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const q = query(
      collection(db, "notices"),
      where("expireOn", ">", new Date()),
      orderBy("expireOn", "desc")
    );
    getDocs(q).then((snap) => {
      setNotices(snap.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
  }, []);

  const addNotice = async (noticeData) => {
    setLoading(true);
    const attachment = !!noticeData.attachment
      ? await saveDoc(noticeData.attachment)
      : null;
    const notice = { ...noticeData, attachment };
    const ref = collection(db, "notices");
    addDoc(ref, notice)
      .then((doc) => {
        setNotices([{ ...notice, id: doc.id }, ...notices]);
      })
      .finally(() => setLoading(false));
  };

  const removeNotice = (id) => {
    setLoading(true);
    const ref = doc(db, "notices", id);
    deleteDoc(ref)
      .then(() => setNotices(notices.filter((notice) => notice.id !== id)))
      .finally(() => setLoading(false));
  };

  const value = {
    notices,
    addNotice,
    removeNotice,
    loading,
  };

  return (
    <NoticesContext.Provider value={value}>
      {props.children}
    </NoticesContext.Provider>
  );
};

export default NoticesProvider;
