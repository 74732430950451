import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { useHistory } from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const FeaturesTiles = (props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...rest
  } = props;

  const history = useHistory();

  const outerClasses = classNames(
    "features-tiles section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "OUR CORE ACTIVITIES",
    paragraph: "We Bring People Together. We Believe Love Unites Them !",
  };

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item">
              <div
                className="tiles-item-inner"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/activities/vSlOLPH5doC105138cw0")}
              >
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16 reveal-from-top"
                    data-reveal-container=".tiles-item"
                  >
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-01.svg")}
                      alt="Features tile icon 01"
                      width={72}
                      height={72}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4
                    className="h5 mt-0 mb-8 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="100"
                  >
                    Cultural Activities
                  </h4>
                </div>
              </div>
            </div>

            <div className="tiles-item">
              <div
                className="tiles-item-inner"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/activities/iv2PNMcdgqGrsWZFTmv8")}
              >
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="100"
                  >
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-02.svg")}
                      alt="Features tile icon 02"
                      width={72}
                      height={72}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4
                    className="h5 mt-0 mb-8 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="200"
                  >
                    Annual Sports
                  </h4>
                </div>
              </div>
            </div>

            <div className="tiles-item">
              <div
                className="tiles-item-inner"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/activities/8joZ0qbiuwFgpBewetQc")}
              >
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="200"
                  >
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-03.svg")}
                      alt="Features tile icon 03"
                      width={72}
                      height={72}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4
                    className="h5 mt-0 mb-8 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="300"
                  >
                    Puja Celebration
                  </h4>
                </div>
              </div>
            </div>

            <div className="tiles-item">
              <div
                className="tiles-item-inner"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/activities/iv2PNMcdgqGrsWZFTmv8")}
              >
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16 reveal-from-top"
                    data-reveal-container=".tiles-item"
                  >
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-04.svg")}
                      alt="Features tile icon 04"
                      width={72}
                      height={72}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4
                    className="h5 mt-0 mb-8 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="100"
                  >
                    Social Activities
                  </h4>
                </div>
              </div>
            </div>

            <div className="tiles-item">
              <div
                className="tiles-item-inner"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/activities/enK30blg12FYoBH9SGKw")}
              >
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="100"
                  >
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-05.svg")}
                      alt="Features tile icon 05"
                      width={72}
                      height={72}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4
                    className="h5 mt-0 mb-8 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="200"
                  >
                    Library
                  </h4>
                </div>
              </div>
            </div>

            <div className="tiles-item">
              <div
                className="tiles-item-inner"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/activities/i4H93g6uJuiEhtcaWP3V")}
              >
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="200"
                  >
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-06.svg")}
                      alt="Features tile icon 06"
                      width={72}
                      height={72}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4
                    className="h5 mt-0 mb-8 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="300"
                  >
                    Community Walefare
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
