export const sendMembershipApproveEmail = (to, name, url) => {
  window.Email.send({
    Username: "info@cfsaltlake.org",
    Password: "CfBlock@0627",
    Host: "mail.cfsaltlake.org",
    Port: 465,
    Body: `Hi ${name},\n\nYour application has been approved, please pay the membership fee from the link : ${url}.\n\nThanks,\nThe Cf Block SaltLake`,
    From: "info@cfsaltlake.org",
    To: to,
    Subject: "Cf Block SaltLake Membership Approved",
  });
};

export const sendMembershipRejectEmail = (to, name) => {
  window.Email.send({
    Username: "info@cfsaltlake.org",
    Password: "CfBlock@0627",
    Host: "mail.cfsaltlake.org",
    Port: 465,
    Body: `Hi ${name},\n\nYour application has been rejected.\n\nThanks,\nThe Cf Block SaltLake`,
    From: "info@cfsaltlake.org",
    To: to,
    Subject: "Cf Block SaltLake Membership Approved",
  });
};
