import React from "react";
import Cta from "../components/sections/Cta";
import Pricing from "../components/sections/Pricing";
import MembershipProvider from "../providers/MembershipProvider";

const Membership = () => {
  return (
    <MembershipProvider>
      <Pricing hasBgColor />
      <Cta hasBgColor invertColor split className="illustration-element-08" />
    </MembershipProvider>
  );
};

export default Membership;
