import React from "react";
import { db } from "../config/firebase";
import {
  doc,
  addDoc,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  query,
  collection,
  where,
} from "firebase/firestore/lite";
import {
  sendMembershipApproveEmail,
  sendMembershipRejectEmail,
} from "../utils/emails";

export const MembershipContext = React.createContext();

const PaymentLinks = {
  Annual: "https://rzp.io/l/BE06ZrTE",
  Associate: "https://rzp.io/l/iHbBcFPx",
  Lifetime: "https://rzp.io/l/Lq6Tfs3QiG",
};

const MembershipProvider = (props) => {
  const [data, setData] = React.useState({
    description: "",
    form1: "",
    form2: "",
    form3: "",
    form1Description: "",
    form2Description: "",
    form3Description: "",
  });

  const [applications, setApplications] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getData();
    loadApplications();
  }, []);

  const getData = () => {
    const ref = doc(db, "membership", "membership");
    getDoc(ref).then((snap) => {
      setData(snap.data());
    });
  };

  const loadApplications = () => {
    const q = query(
      collection(db, "applications"),
      where("status", "==", "pending")
    );
    getDocs(q).then((snap) => {
      setApplications(
        snap.docs.map((docsnap) => ({ ...docsnap.data(), id: docsnap.id }))
      );
    });
  };

  const updateData = (dataInput) => {
    setLoading(true);
    const ref = doc(db, "membership", "membership");
    setDoc(ref, dataInput)
      .then(() => setData(dataInput))
      .finally(() => setLoading(false));
  };

  const addApplication = async (url, email, name, membership) => {
    setLoading(true);
    const ref = collection(db, "applications");
    await addDoc(ref, { url, email, name, membership, status: "pending" })
      .then(() => loadApplications())
      .finally(() => setLoading(false));
  };

  const approveApplication = (approved) => {
    const ref = doc(db, "applications", approved.id);
    setLoading(true);
    updateDoc(ref, { status: "approved" })
      .then(() => {
        sendMembershipApproveEmail(
          approved.email,
          approved.name,
          PaymentLinks[approved.membership]
        );
        setApplications(applications.filter((app) => app.id !== approved.id));
      })
      .finally(() => setLoading(false));
  };

  const rejectApplication = (rejected) => {
    const ref = doc(db, "applications", rejected.id);
    setLoading(true);
    updateDoc(ref, { status: "rejected" })
      .then(() => {
        sendMembershipRejectEmail(rejected.email, rejected.name);
        setApplications(applications.filter((app) => app.id !== rejected.id));
      })
      .finally(() => setLoading(false));
  };

  const value = {
    data,
    applications,
    loading,
    updateData,
    addApplication,
    approveApplication,
    rejectApplication,
  };

  return (
    <MembershipContext.Provider value={value}>
      {props.children}
    </MembershipContext.Provider>
  );
};

export default MembershipProvider;
