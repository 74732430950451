import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from "../components/elements/Button";
import GenericSection from "../components/sections/GenericSection";
import NoticesProvider, { NoticesContext } from "../providers/NoticesProvider";

const NoticeBoardTable = () => {
  const { notices } = useContext(NoticesContext);
  const history = useHistory();

  return (
    <div className="table-responsive">
      {notices.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th>IDX</th>
              <th>Subject</th>
              <th>Expires On</th>
              <th>Created On</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {notices.map((notice, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{notice.subject}</td>
                <td>{notice.expireOn?.toDate()?.toLocaleDateString()}</td>
                <td>{notice.createdOn?.toDate()?.toLocaleDateString()}</td>
                <td>
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push(`/notices/${notice.id}`);
                    }}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {notices.length === 0 && (
        <div className="p-24 center-content">No current notice</div>
      )}
    </div>
  );
};

const NoticeBoard = () => {
  return (
    <NoticesProvider>
      <GenericSection>
        <div className="container-sm">
          <h1 className="center-content">Notice Board</h1>
          <NoticeBoardTable />
        </div>
      </GenericSection>
    </NoticesProvider>
  );
};

export default NoticeBoard;
