import React from "react";
import "./App.css";
import { withRouter, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import AdminRoute from "./utils/AdminRoute";
import AuthRoute from "./utils/AuthRoute";
import ScrollReveal from "./utils/ScrollReveal";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import LayoutAlternative from "./layouts/LayoutAlternative";
import LayoutSignin from "./layouts/LayoutSignin";

// Views
import Home from "./views/Home";
import Secondary from "./views/Secondary";
import Login from "./views/Login";
import Signup from "./views/Signup";
import AboutUs from "./views/AboutUs";
import Activities from "./views/Activities";
import Activity from "./views/Activity";
import Admin from "./views/Admin";
import EditHome from "./views/EditHome";
import EditActivities from "./views/EditActivities";
import EditActivity from "./views/EditActivity";
import Membership from "./views/Membership";
import EditAbout from "./views/EditAbout";
import Contact from "./views/Contact";
import EditContact from "./views/EditContact";
import EditMembership from "./views/EditMembership";
import Payments from "./views/Payments";
import EditPayments from "./views/EditPayments";
import NoticeBoard from "./views/NoticeBoard";
import EditNoticeBoard from "./views/EditNoticeBoard";
import EditNotice from "./views/EditNotice";
import Notice from "./views/Notice";

class App extends React.Component {
  componentDidMount() {
    document.body.classList.add("is-loaded");
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute
              exact
              path="/about-us"
              component={AboutUs}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/membership"
              component={Membership}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/payments"
              component={Payments}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/activities"
              component={Activities}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/activities/:id"
              component={Activity}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/notices"
              component={NoticeBoard}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/notices/:id"
              component={Notice}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/contact"
              component={Contact}
              layout={LayoutAlternative}
            />
            <AuthRoute
              exact
              path="/login"
              component={Login}
              layout={LayoutSignin}
            />
            <AuthRoute
              exact
              path="/signup"
              component={Signup}
              layout={LayoutSignin}
            />
            <AdminRoute
              exact
              path="/admin"
              component={Admin}
              layout={LayoutDefault}
            />
            <AdminRoute
              exact
              path="/edit-home"
              component={EditHome}
              layout={LayoutDefault}
            />
            <AdminRoute
              exact
              path="/edit-about"
              component={EditAbout}
              layout={LayoutDefault}
            />
            <AdminRoute
              exact
              path="/edit-membership"
              component={EditMembership}
              layout={LayoutDefault}
            />
            <AdminRoute
              exact
              path="/edit-payments"
              component={EditPayments}
              layout={LayoutDefault}
            />
            <AdminRoute
              exact
              path="/edit-contact"
              component={EditContact}
              layout={LayoutDefault}
            />
            <AdminRoute
              exact
              path="/edit-activities"
              component={EditActivities}
              layout={LayoutDefault}
            />
            <AdminRoute
              exact
              path="/edit-activities/:id"
              component={EditActivity}
              layout={LayoutDefault}
            />
            <AdminRoute
              exact
              path="/edit-notices"
              component={EditNoticeBoard}
              layout={LayoutDefault}
            />
            <AdminRoute
              exact
              path="/edit-notices/:id"
              component={EditNotice}
              layout={LayoutDefault}
            />
          </Switch>
        )}
      />
    );
  }
}

export default withRouter((props) => <App {...props} />);
