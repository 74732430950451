import React from "react";
import { saveDoc, savePhoto, saveVideo } from "../utils/firebase";
import GenericSection from "../components/sections/GenericSection";
import Button from "../components/elements/Button";
import Input from "../components/elements/Input";
import Modal from "../components/elements/Modal";
import AboutProvider, { AboutContext } from "../providers/AboutProvider";
import Image from "../components/elements/Image";
import UpdateMembers from "../components/sections/UpdateMembers";

const EditAboutChild = () => {
  const { about, updateAbout, loading } = React.useContext(AboutContext);

  const [state, setState] = React.useState({
    title: "",
    description: "",
    image: "",
    video: "",
    download: "",
    download2: "",
    members: [],
  });
  const [videoModalActive, setVideoModalActive] = React.useState(false);
  const [videoLoading, setVideoLoading] = React.useState(false);
  const [membersLoading, setMembersLoading] = React.useState(false);
  const [gMembersLoading, setGMembersLoading] = React.useState(false);

  React.useEffect(() => {
    setState(about);
  }, [about]);

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  const handleLeadImage = async (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      const url = await savePhoto(e.target.files[0]);
      setState({ ...state, image: url });
    }
  };

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  const handleVideo = async (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      setVideoLoading(true);
      const url = await saveVideo(e.target.files[0]);
      setState({ ...state, video: url });
      setVideoLoading(false);
    }
  };

  const handleUploadMembers = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.hidden = true;

    input.onchange = async (e) => {
      const file = e.target.files[0];
      setMembersLoading(true);
      const url = await saveDoc(file);
      setState({ ...state, download: url });
      setMembersLoading(false);
    };

    input.click();
  };

  const handleUploadGMembers = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.hidden = true;

    input.onchange = async (e) => {
      const file = e.target.files[0];
      setGMembersLoading(true);
      const url = await saveDoc(file);
      setState({ ...state, download2: url });
      setGMembersLoading(false);
    };

    input.click();
  };

  return (
    <React.Fragment>
      <GenericSection bottomDivider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Edit About</h1>
          <Button
            color={"primary"}
            loading={loading}
            onClick={() => updateAbout(state)}
          >
            Save Changes
          </Button>
        </div>
      </GenericSection>
      <GenericSection>
        <div className="container-xs">
          <Input
            type="text"
            label="Title"
            className="mb-16"
            value={state.title}
            onChange={(e) => setState({ ...state, title: e.target.value })}
          />
          <Input
            type="textarea"
            label="Description"
            className="mb-16"
            rows={10}
            value={state.description}
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
          />
          {!!state.image ? (
            <React.Fragment>
              <h6>About Image</h6>
              <img src={state.image} />
              <div className="mt-24 center-content">
                <Button
                  color={"secondary"}
                  size={"sm"}
                  onClick={(e) => setState({ ...state, image: "" })}
                >
                  Remove
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <Input type="file" label="About Image" onChange={handleLeadImage} />
          )}
          {!!state.video ? (
            <React.Fragment>
              <h6>About Video</h6>
              <a
                data-video={state.video}
                href="#"
                aria-controls="video-modal"
                onClick={() => setVideoModalActive(true)}
              >
                <Image
                  className="has-shadow"
                  src={require("../assets/images/video-placeholder.svg")}
                  alt="Video"
                  width={712}
                  height={400}
                />
              </a>
              <Modal
                id="video-modal"
                show={videoModalActive}
                handleClose={() => setVideoModalActive(false)}
                video={state.video}
                videoTag="iframe"
              />
              <div className="mt-24 center-content">
                <Button
                  color={"secondary"}
                  size={"sm"}
                  onClick={(e) => setState({ ...state, video: "" })}
                >
                  Remove
                </Button>
              </div>
            </React.Fragment>
          ) : !videoLoading ? (
            <Input
              type="file"
              accept="video/*"
              label="About Video"
              onChange={handleVideo}
            />
          ) : (
            <Button color={"secondary"} size={"sm"}>
              Uploading Video...
            </Button>
          )}
          <div className="button-group mt-32">
            <Button
              color="primary"
              wideMobile
              loading={membersLoading}
              onClick={handleUploadMembers}
            >
              Upload Members
            </Button>
            <Button
              color="dark"
              wideMobile
              loading={gMembersLoading}
              onClick={handleUploadGMembers}
            >
              Upload Governing Body Members
            </Button>
          </div>
        </div>
      </GenericSection>
      <UpdateMembers
        members={state.members}
        setMembers={(members) => setState({ ...state, members })}
      />
    </React.Fragment>
  );
};

const EditAbout = () => {
  return (
    <AboutProvider>
      <EditAboutChild />
    </AboutProvider>
  );
};

export default EditAbout;
