import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCd5-Sp6icaWePIs5l8aqKpbghs3W9qqD0",
  authDomain: "cf-saltlake.firebaseapp.com",
  projectId: "cf-saltlake",
  storageBucket: "cf-saltlake.appspot.com",
  messagingSenderId: "947271300821",
  appId: "1:947271300821:web:97d844d52e9c8f067a545c",
  measurementId: "G-9V9X3C28YP"
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);