import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import Button from "../elements/Button";
import Image from "../elements/Image";
import { HomeContext } from "../../providers/HomeProvider";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const HeroSplit = (props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...rest
  } = props;

  const { home } = React.useContext(HomeContext);

  const outerClasses = classNames(
    "hero section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>
            <div className="split-item">
              <div className="hero-content split-item-content center-content-mobile">
                <h1 className="mt-0 mb-16 reveal-from-left">
                  Salt Lake CF Block Residents' Association
                </h1>
                <h4 className="mt-0 mb-16 reveal-from-left">
                  {home.headline}
                </h4>
                <p
                  className="mt-0 mb-32 reveal-from-left"
                  data-reveal-delay="200"
                >
                  {home.description}
                </p>
                {/* <div className="reveal-from-left" data-reveal-delay="400">
                  <Button
                    tag="a"
                    color="primary"
                    href="https://cruip.com/"
                    wideMobile
                  >
                    Pricing and plans
                  </Button>
                </div> */}
              </div>
              <div className="hero-figure is-3d split-item-image illustration-element-02 reveal-from-right">
                <Image
                  className="has-shadow"
                  src={home.leadImage}
                  alt="Hero"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;
