import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { HomeContext } from "../../providers/HomeProvider";
import PropTypes from "prop-types";
import Button from "../elements/Button";
import Modal from "../elements/Modal";
import Input from "../elements/Input";
import { savePhoto } from "../../utils/firebase";

const propTypes = {
  ...SectionSplitProps.types,
  features: PropTypes.array,
  setFeatures: PropTypes.func,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const Features = (props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    features,
    setFeatures,
    ...rest
  } = props;

  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Update Sections",
    paragraph:
      "Social Activities - Cultural Activities - Social Awareness Program - Community Hall Services",
  };

  const [demoModalActive, setDemoModalActive] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formState, setFormState] = React.useState({
    title: "",
    description: "",
    image: null,
  });

  const openModal = () => {
    setDemoModalActive(true);
  };

  const closeModal = () => {
    setDemoModalActive(false);
  };

  const handleRemove = (index) => {
    const newFeatures = features.filter((_, i) => i !== index);
    setFeatures(newFeatures);
  };

  const handleSave = async () => {
    setLoading(true);
    const image = !!formState.image ? await savePhoto(formState.image) : null;
    const newFeatures = [...features, { ...formState, image }];
    setFeatures(newFeatures);
    setFormState({
      title: "",
      description: "",
      image: null,
    });
    setLoading(false);
    closeModal();
  };

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content"
          ></SectionHeader>
          <div className={splitClasses}>
            {features.map((section, index) => (
              <div key={index} className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">{section.title}</h3>
                  <p className="m-0">{section.description}</p>
                  <Button
                    size={"sm"}
                    color={"secondary"}
                    onClick={() => handleRemove(index)}
                  >
                    Remove
                  </Button>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile",
                    imageFill && "split-item-image-fill"
                  )}
                >
                  <Image
                    src={require("./../../assets/images/features-split-image.svg")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                  <div style={imgStyle}>
                    <Image
                      src={section.image}
                      alt="Features split top 01"
                      width={738}
                      height={570}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="center-content">
            <Button
              color={"primary"}
              aria-controls="demo-modal"
              onClick={openModal}
            >
              Add New Section
            </Button>
          </div>
        </div>
      </div>

      <Modal id="demo-modal" show={demoModalActive} handleClose={closeModal}>
        <Input
          type="file"
          label="Image"
          formGroup="desktop"
          labelHidden={false}
          onChange={(e) =>
            setFormState({ ...formState, image: e.target.files[0] })
          }
        />
        <Input
          type="text"
          label="Title"
          placeholder="Title here.."
          formGroup="desktop"
          labelHidden={false}
          onChange={(e) =>
            setFormState({ ...formState, title: e.target.value })
          }
        />
        <Input
          type="text"
          label="Description"
          placeholder="Descrion here.."
          formGroup="desktop"
          labelHidden={false}
          onChange={(e) =>
            setFormState({ ...formState, description: e.target.value })
          }
        />
        <div className="center-content mt-24">
          <Button color="primary" loading={loading} onClick={handleSave}>
            Add
          </Button>
          <a
            className="func-link text-xxs fw-500 tt-u ml-24"
            aria-label="close"
            href="#0"
            onClick={closeModal}
          >
            Cancel
          </a>
        </div>
      </Modal>
    </section>
  );
};

const imgStyle = {
  position: "absolute",
  width: "100%",
  maxWidth: "100%",
  top: "0",
  left: "0",
};

Features.propTypes = propTypes;
Features.defaultProps = defaultProps;

export default Features;
