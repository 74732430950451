import { doc, getDoc, setDoc } from "firebase/firestore/lite";
import React from "react";
import { db } from "../config/firebase";

export const ContactContext = React.createContext();

const ContactProvider = (props) => {
  const [contactInfo, setContactInfo] = React.useState({
    address: "",
    phoneNumbers: "",
    emailIds: "",
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getContactInfo();
  }, []);

  const getContactInfo = () => {
    const ref = doc(db, "contactInfo", "contactInfo");
    getDoc(ref).then((doc) => {
      setContactInfo(doc.data());
    });
  };

  const updateContactInfo = (contactInfo) => {
    setLoading(true);
    const ref = doc(db, "contactInfo", "contactInfo");
    setDoc(ref, contactInfo)
      .then(() => setContactInfo(contactInfo))
      .finally(() => setLoading(false));
  };

  const value = {
    contactInfo,
    updateContactInfo,
    loading,
  };

  return (
    <ContactContext.Provider value={value}>
      {props.children}
    </ContactContext.Provider>
  );
};

export default ContactProvider;
