import React from "react";
import Button from "../components/elements/Button";
import Input from "../components/elements/Input";
import GenericSection from "../components/sections/GenericSection";
import { saveDoc } from "../utils/firebase";
import MembershipProvider, {
  MembershipContext,
} from "../providers/MembershipProvider";

const forms = [
  { name: "form1", label: "Annual Membership Form" },
  { name: "form2", label: "Associate Membership Form" },
  { name: "form3", label: "Lifetime Membership Form" },
];

const EditMembershipChild = () => {
  const [pageData, setPageData] = React.useState({
    description: "",
    form1: "",
    form2: "",
    form3: "",
    form1Description: "",
    form2Description: "",
    form3Description: "",
  });
  const [uploading, setUploading] = React.useState(false);

  const { data, updateData, loading } = React.useContext(MembershipContext);

  React.useEffect(() => {
    setPageData(data);
  }, [data]);

  const handleUploadForm = (form) => {
    const input = document.createElement("input");
    input.type = "file";
    input.hidden = true;

    input.onchange = async (e) => {
      const file = e.target.files[0];
      setUploading(true);
      const url = await saveDoc(file);
      setPageData({ ...pageData, [form]: url });
      setUploading(false);
    };

    input.click();
  };

  return (
    <GenericSection>
      <div className="container-xs">
        <h1>Update Membership Info</h1>
        <Input
          type="textarea"
          label="Description"
          name="description"
          value={pageData.description}
          onChange={(e) =>
            setPageData({ ...pageData, description: e.target.value })
          }
        />
        {forms.map((form) => (
          <div className="has-bottom-divider section-inner mb-32">
            <Input
              type="textarea"
              label={`${form.label} Description`}
              value={pageData[`${form.name}Description`]}
              onChange={(e) =>
                setPageData({
                  ...pageData,
                  [`${form.name}Description`]: e.target.value,
                })
              }
            />
            <div className="button-group mt-32">
              <Button
                color="primary"
                wideMobile
                loading={uploading}
                onClick={() => handleUploadForm(form.name)}
              >
                Upload {form.label}
              </Button>
              {!!pageData[form.name] && (
                <Button
                  color="dark"
                  wideMobile
                  onClick={() => window.open(pageData[form.name], "_blank")}
                >
                  View Form
                </Button>
              )}
            </div>
          </div>
        ))}
        <div className="p-24 center-content">
          <Button
            loading={loading || uploading}
            onClick={() => updateData(pageData)}
            color={"primary"}
          >
            Save
          </Button>
        </div>
      </div>
    </GenericSection>
  );
};

const ApplicationList = () => {
  const { applications, loading, approveApplication, rejectApplication } =
    React.useContext(MembershipContext);

  return (
    <GenericSection>
      <div className="container-sm">
        <h1>Pending Applications</h1>
        {loading && <div className="p-24 center-content">Loading...</div>}
        {!loading && applications.length === 0 && (
          <div className="p-24 center-content">No applications pending</div>
        )}
        {!loading && applications.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Membership</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {applications.map((application) => (
                <tr key={application.id}>
                  <td>{application.name}</td>
                  <td>{application.email}</td>
                  <td>{application.membership}</td>
                  <td>
                    <Button
                      color="primary"
                      onClick={() => approveApplication(application)}
                    >
                      Approve
                    </Button>
                    <Button
                      color="danger"
                      onClick={() => rejectApplication(application)}
                    >
                      Reject
                    </Button>
                    <Button
                      color="dark"
                      onClick={() => window.open(application.url, "_blank")}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </GenericSection>
  );
};

const EditMembership = () => {
  return (
    <MembershipProvider>
      <EditMembershipChild />
      <ApplicationList />
    </MembershipProvider>
  );
};

export default EditMembership;
