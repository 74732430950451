import { doc, getDoc, setDoc } from "firebase/firestore/lite";
import React from "react";
import { db } from "../config/firebase";

export const AboutContext = React.createContext();

const AboutProvider = (props) => {
  const [about, setAbout] = React.useState({
    title: "",
    description: "",
    image: "",
    video: "",
    download: "",
    download2: "",
    members: [],
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const aboutRef = doc(db, "about", "about");
    getDoc(aboutRef).then((doc) => {
      setAbout(doc.data());
    });
  }, []);

  /**
   *
   * @param {{
   * title: string;
   * description: string;
   * image: string;
   * video: string;
   * download: string;
   * download2: string;
   * members: {name: string; image: string; role: string;}[]
   * }} about
   */
  const updateAbout = (about) => {
    setLoading(true);
    const aboutRef = doc(db, "about", "about");
    setDoc(aboutRef, about)
      .then(() => setAbout(about))
      .finally(() => setLoading(false));
  };

  const value = {
    about,
    updateAbout,
    loading,
  };

  return (
    <AboutContext.Provider value={value}>
      {props.children}
    </AboutContext.Provider>
  );
};

export default AboutProvider;
