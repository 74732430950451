import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import PropTypes from "prop-types";
import Button from "../elements/Button";
import Modal from "../elements/Modal";
import Input from "../elements/Input";
import { savePhoto, submitContact } from "../../utils/firebase";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const ContactForm = (props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...rest
  } = props;

  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Contact Us",
    paragraph: "GET IN TOUCH",
  };

  const [formState, setFormState] = React.useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    message: "",
  });

  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    submitContact(formState)
      .then(() => {
        setLoading(false);
        setFormState({
          name: "",
          phone: "",
          email: "",
          address: "",
          message: "",
        });
      })
      .catch((error) => {
        setLoading(false);
        alert(error);
      });
  };

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content">
            <div className="features-split-icon mb-24">
              <Image
                src={require("./../../assets/images/features-split-icon.svg")}
                alt="Features split icon"
                width={80}
                height={80}
              />
            </div>
          </SectionHeader>
          <div className={splitClasses}>
            <div className="split-item">
              <div className="split-item-content center-content-mobile">
                <h3 className="mt-0 mb-16">Contact Form</h3>
                <form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    label="Name"
                    name="name"
                    placeholder="Name here..."
                    value={formState.name}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    label="Phone Number"
                    name="phone"
                    placeholder="Phone number here..."
                    value={formState.phone}
                    onChange={handleChange}
                  />
                  <Input
                    type="email"
                    label="Email"
                    name="email"
                    placeholder="Email here..."
                    value={formState.email}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    label="Address"
                    name="address"
                    placeholder="Address here..."
                    value={formState.address}
                    onChange={handleChange}
                  />
                  <Input
                    type="textarea"
                    label="Message"
                    name="message"
                    placeholder="How to assist you?"
                    value={formState.message}
                    onChange={handleChange}
                  />
                  <div className="center-content p-24">
                    <Button wideMobile color="dark" loading={loading}>
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile",
                  imageFill && "split-item-image-fill"
                )}
              >
                <Image
                  src={require("./../../assets/images/features-split-image.svg")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
                <div style={imgStyle}>
                  <Image
                    src={require("./../../assets/images/beyond-image.png")}
                    alt="Features split top 01"
                    width={738}
                    height={570}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const imgStyle = {
  position: "absolute",
  width: "100%",
  maxWidth: "100%",
  top: "0",
  left: "0",
};

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;
