import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import { HomeContext } from "../../providers/HomeProvider";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Clients = (props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...rest
  } = props;

  const { home } = React.useContext(HomeContext);

  const outerClasses = classNames(
    "clients section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "clients-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <ul className="list-reset">
            {home.gallery.map((item, index) => (
              <li key={index}>
                <Zoom>
                <Image src={item} alt="Client 01" width={200} height={134} />
                </Zoom>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

Clients.propTypes = propTypes;
Clients.defaultProps = defaultProps;

export default Clients;
