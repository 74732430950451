import React from "react";
import ActivityDetails from "../components/sections/ActivityDetails";
import Cta from "../components/sections/Cta";
import ActivityProvider from "../providers/ActivityProvider";

const Activity = (props) => {
    const {id} = props.match.params;

    return (
        <ActivityProvider id={id}>
            <ActivityDetails />
            <Cta hasBgColor invertColor split className="illustration-element-08" />
        </ActivityProvider>
    );
};

export default Activity;