import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <Link to="/contact/">Contact</Link>
        </li>
        <li>
          <Link to="/about-us/">About us</Link>
        </li>
        <li>
          <Link to="/notices/">Notice Board</Link>
        </li>
        <li>
          <Link to="/membership/">Membership</Link>
        </li>
        <li>
          <Link to="/payments/">Payments</Link>
        </li>
        <li>
          <Link to="/activities/">Activities</Link>
        </li>
        <li>
          <a href="https://blog.cfsaltlake.org/" target="_blank">
            Blogs & Gallery
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
