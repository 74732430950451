import React from "react";
import PropTypes from "prop-types";
import { db } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore/lite";

export const ActivityContext = React.createContext();

const ActivityProvider = (props) => {
  const [activity, setActivity] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const activityRef = doc(db, "activities", props.id);
    getDoc(activityRef).then((doc) => {
      setActivity(doc.data());
    });
  }, []);

  const updateActivity = (activity, id) => {
    const ref = doc(db, "activities", id);
    setLoading(true);
    setDoc(ref, activity)
      .then(() => setActivity(activity))
      .finally(() => setLoading(false));
  };

  const value = {
    activity,
    updateActivity,
    loading,
  };

  return (
    <ActivityContext.Provider value={value}>
      {props.children}
    </ActivityContext.Provider>
  );
};

ActivityProvider.propTypes = {
  id: PropTypes.string,
};

export default ActivityProvider;
