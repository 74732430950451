import React from "react";
import { saveDoc } from "../utils/firebase";
import GenericSection from "../components/sections/GenericSection";
import Button from "../components/elements/Button";
import Input from "../components/elements/Input";
import NoticeProvider, { NoticeContext } from "../providers/NoticeProvider";

const EditNoticeChild = ({ id }) => {
  const { notice, updateNotice, loading } = React.useContext(NoticeContext);

  const [state, setState] = React.useState({
    subject: "",
    description: "",
    attachment: null,
    expireOn: null,
  });
  const [uploading, setUploading] = React.useState(false);

  React.useEffect(() => {
    setState(notice);
  }, [notice]);

  const handleUploadForm = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.hidden = true;

    input.onchange = async (e) => {
      const file = e.target.files[0];
      setUploading(true);
      const url = await saveDoc(file);
      setState({ ...state, attachment: url });
      setUploading(false);
    };

    input.click();
  };

  return (
    <React.Fragment>
      <GenericSection bottomDivider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Edit Notice</h1>
          <Button
            color={"primary"}
            loading={loading}
            onClick={() => updateNotice(state, id)}
          >
            Save Changes
          </Button>
        </div>
      </GenericSection>
      <GenericSection>
        <div className="container-xs">
          <Input
            type="text"
            label="Subject"
            className="mb-16"
            value={state.subject}
            onChange={(e) => setState({ ...state, subject: e.target.value })}
          />
          <Input
            type="textarea"
            label="Description"
            className="mb-16"
            rows={10}
            value={state.description}
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
          />
          <Input
            type="date"
            label="Expire On"
            className="mb-16"
            onChange={(e) =>
              setState({ ...state, expireOn: new Date(e.target.value) })
            }
          />
          <div className="button-group mt-32">
            <Button
              color="primary"
              loading={uploading}
              wideMobile
              onClick={() => handleUploadForm()}
            >
              Upload Attachment
            </Button>
            {!!state.attachment && (
              <Button
                color="dark"
                wideMobile
                onClick={() => window.open(state.attachment, "_blank")}
              >
                View Attachment
              </Button>
            )}
          </div>
        </div>
      </GenericSection>
    </React.Fragment>
  );
};

const EditNotice = (props) => {
  const id = props.match.params.id;
  return (
    <NoticeProvider id={id}>
      <EditNoticeChild id={id} />
    </NoticeProvider>
  );
};

export default EditNotice;
