import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import Button from "../elements/Button";
import Modal from "../elements/Modal";
import Input from "../elements/Input";
import PropTypes from "prop-types";
import { savePhoto } from "../../utils/firebase";

const propTypes = {
  ...SectionTilesProps.types,
  members: PropTypes.array,
  setMembers: PropTypes.func,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class UpdateMembers extends React.Component {
  state = {
    loading: false,
    name: "",
    role: "",
    image: null,
    modal: null,
  };

  handleSave = async () => {
    this.setState({ loading: true });
    const image = !!this.state.image ? await savePhoto(this.state.image) : null;
    const newMembers = [
      ...this.props.members,
      { name: this.state.name, role: this.state.role, image },
    ];
    this.props.setMembers(newMembers);
    this.setState({
      name: "",
      role: "",
      image: null,
      modal: null,
      loading: false,
    });
  };

  handleUpdate = async () => {
    this.setState({ loading: true });
    const image = !!this.state.image ? await savePhoto(this.state.image) : null;
    const newMembers = [...this.props.members];
    const member = newMembers[this.state.modal];
    member.name = this.state.name;
    member.role = this.state.role;
    member.image = image || member.image;
    this.props.setMembers(newMembers);
    this.setState({
      name: "",
      role: "",
      image: null,
      modal: null,
      loading: false,
    });
  };

  openModal = (purpose) => {
    if (typeof purpose === "number") {
      this.setState({
        modal: purpose,
        name: this.props.members[purpose].name,
        role: this.props.members[purpose].role,
      });
    } else {
      this.setState({ modal: purpose });
    }
  };

  closeModal = () => {
    this.setState({ modal: null });
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      members,
      setMembers,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "team section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "team-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "Update Core Members",
      paragraph: "Add or remove core members",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              {members.map((member, index) => (
                <div key={index} className="tiles-item">
                  <div className="tiles-item-inner">
                    <div className="team-item-header">
                      <div className="team-item-image mb-16">
                        <Image
                          src={member.image}
                          alt="Features tile icon 01"
                          width={128}
                          height={128}
                        />
                      </div>
                    </div>
                    <div className="team-item-content">
                      <h5 className="team-item-name mt-0 mb-4">
                        {member.name}
                      </h5>
                      <div className="team-item-role text-xxs tt-u text-color-primary mb-8">
                        {member.role}
                      </div>
                      <Button
                        size={"sm"}
                        onClick={() =>
                          setMembers([
                            ...members.filter((_, idx) => idx !== index),
                          ])
                        }
                      >
                        Remove
                      </Button>
                      <Button
                        size={"sm"}
                        onClick={() => this.openModal(index)}
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="center-content">
              <Button
                color={"primary"}
                aria-controls="demo-modal"
                onClick={() => this.openModal("add")}
              >
                Add New Core Member
              </Button>
            </div>
          </div>
        </div>

        <Modal
          id="demo-modal"
          show={this.state.modal !== null}
          handleClose={this.closeModal}
        >
          <Input
            type="file"
            label="Image"
            formGroup="desktop"
            labelHidden={false}
            onChange={(e) => this.setState({ image: e.target.files[0] })}
          />
          <Input
            type="text"
            label="Name"
            placeholder="Name here.."
            formGroup="desktop"
            labelHidden={false}
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
          <Input
            type="text"
            label="Role"
            placeholder="Role here.."
            formGroup="desktop"
            labelHidden={false}
            value={this.state.role}
            onChange={(e) => this.setState({ role: e.target.value })}
          />
          <div className="center-content mt-24">
            <Button
              color="primary"
              loading={this.state.loading}
              onClick={
                this.state.modal === "add" ? this.handleSave : this.handleUpdate
              }
            >
              {this.state.modal === "add" ? "Add" : "Update"}
            </Button>
            <a
              className="func-link text-xxs fw-500 tt-u ml-24"
              aria-label="close"
              href="#0"
              onClick={this.closeModal}
            >
              Cancel
            </a>
          </div>
        </Modal>
      </section>
    );
  }
}

UpdateMembers.propTypes = propTypes;
UpdateMembers.defaultProps = defaultProps;

export default UpdateMembers;
