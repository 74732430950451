import React from "react";
import ContactForm from "../components/sections/ContactForm";
import ContactInfo from "../components/sections/ContactInfo";
import Cta from "../components/sections/Cta";
import ContactProvider from "../providers/ContactProvider";

const Contact = () => {
  return (
    <ContactProvider>
      <ContactForm />
      <ContactInfo topOuterDivider />
      <Cta hasBgColor invertColor split className="illustration-element-08" />
    </ContactProvider>
  );
};

export default Contact;
