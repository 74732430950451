import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
} from "firebase/firestore/lite";
import React from "react";
import { db } from "../config/firebase";
import { savePhoto } from "../utils/firebase";

export const ActivitiesContext = React.createContext();

const ActivitiesProvider = (props) => {
  const [activities, setActivities] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const q = query(collection(db, "activities"));
    getDocs(q).then((snap) => {
      setActivities(snap.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
  }, []);

  const addActivity = async (activityData) => {
    setLoading(true);
    const image = !!activityData.image
      ? await savePhoto(activityData.image)
      : null;
    const activity = { ...activityData, image };
    const ref = collection(db, "activities");
    addDoc(ref, activity)
      .then((doc) => {
        setActivities([...activities, { ...activity, id: doc.id }]);
      })
      .finally(() => setLoading(false));
  };

  const removeActivity = (id) => {
    setLoading(true);
    const ref = doc(db, "activities", id);
    deleteDoc(ref)
      .then(() =>
        setActivities(activities.filter((activity) => activity.id !== id))
      )
      .finally(() => setLoading(false));
  };

  const value = {
    activities,
    addActivity,
    removeActivity,
    loading,
  };

  return (
    <ActivitiesContext.Provider value={value}>
      {props.children}
    </ActivitiesContext.Provider>
  );
};

export default ActivitiesProvider;
