import React from "react";
import HomeProvider, { HomeContext } from "../providers/HomeProvider";
import { savePhoto } from "../utils/firebase";
import GenericSection from "../components/sections/GenericSection";
import UpdateGallery from "../components/sections/UpdateGallery";
import Features from "../components/sections/Features";
import Button from "../components/elements/Button";
import Input from "../components/elements/Input";

const EditHomeChild = () => {
  const { home, updateHome, loading } = React.useContext(HomeContext);

  const [homeState, setHomeState] = React.useState({
    headline: "",
    description: "",
    leadImage: "",
    sections: [],
    gallery: [],
  });

  React.useEffect(() => {
    setHomeState(home);
  }, [home]);

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  const handleLeadImage = async (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      const url = await savePhoto(e.target.files[0]);
      setHomeState({ ...homeState, leadImage: url });
    }
  };

  return (
    <React.Fragment>
      <GenericSection bottomDivider>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>Edit Home</h1>
            <Button
              color={"primary"}
              loading={loading}
              onClick={() => updateHome(homeState)}
            >
              Save Changes
            </Button>
          </div>
      </GenericSection>
      <GenericSection>
        <div className="container-xs">
          <Input
            type="text"
            label="Headline"
            className="mb-16"
            value={homeState.headline}
            onChange={(e) =>
              setHomeState({ ...homeState, headline: e.target.value })
            }
          />
          <Input
            type="textarea"
            label="Description"
            className="mb-16"
            rows={10}
            value={homeState.description}
            onChange={(e) =>
              setHomeState({ ...homeState, description: e.target.value })
            }
            />
            {!!homeState.leadImage ? (
              <React.Fragment>
                <h6>Banner Image</h6>
                <img src={homeState.leadImage} />
                <div className="mt-24 center-content">
                <Button
                  color={"secondary"}
                  size={"sm"}
                  onClick={(e) => setHomeState({ ...homeState, leadImage: "" })}
                >
                  Remove
                </Button>
                </div>
              </React.Fragment>
            ) : (
              <Input
                type="file"
                label="Banner Image"
                onChange={handleLeadImage}
              />
            )}
        </div>
      </GenericSection>
      <UpdateGallery
        gallery={homeState.gallery}
        setGallery={(gallery) => setHomeState({ ...homeState, gallery })}
      />
      <Features
        features={homeState.sections}
        setFeatures={(sections) => setHomeState({ ...homeState, sections })}
      />
    </React.Fragment>
  );
};

const EditHome = () => {
  return (
    <HomeProvider>
      <EditHomeChild />
    </HomeProvider>
  );
};

export default EditHome;
