import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Logo from "./partials/Logo";
import { AuthContext } from "../../providers/AuthProvider";
import Button from "../elements/Button";

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  active: false,
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = (props) => {
  const {
    className,
    active,
    navPosition,
    hideNav,
    hideSignin,
    bottomOuterDivider,
    bottomDivider,
    ...rest
  } = props;

  const [state, setState] = React.useState({
    isActive: false,
  });

  const { user, logout, loading, error, resolve } =
    React.useContext(AuthContext);

  const nav = React.useRef(null);
  const hamburger = React.useRef(null);

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setState({ isActive: true });
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setState({ isActive: false });
  };

  const keyPress = (e) => {
    state.isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !state.isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  React.useEffect(() => {
    active && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
  }, []);

  React.useEffect(() => {
    if (error) {
      alert(error);
      resolve();
    }
  }, [error]);

  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  return (
    <header {...rest} className={classes} style={{ background: "#ffcf94" }}>
      <div className="container">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          <Logo />
          {!hideNav && (
            <React.Fragment>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={state.isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames(
                  "header-nav",
                  state.isActive && "is-active"
                )}
              >
                <div className="header-nav-inner">
                  <ul
                    className={classNames(
                      "list-reset text-xxs",
                      navPosition && `header-nav-${navPosition}`
                    )}
                  >
                    <li>
                      <Link to="/" onClick={closeMenu}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-us/" onClick={closeMenu}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/membership/" onClick={closeMenu}>
                        Membership
                      </Link>
                    </li>
                    <li>
                      <Link to="/payments/" onClick={closeMenu}>
                        Payments
                      </Link>
                    </li>
                    <li>
                      <Link to="/activities/" onClick={closeMenu}>
                        Activities
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact/" onClick={closeMenu}>
                        Contact
                      </Link>
                    </li>
                    <li>
                      <a href="https://blog.cfsaltlake.org/" target="_blank">
                        Blogs & Gallery
                      </a>
                    </li>
                  </ul>
                  {!hideSignin && (
                    <ul className="list-reset header-nav-right">
                      <li>
                        <Link
                          to="/signup/"
                          className="button button-primary button-sm"
                          onClick={closeMenu}
                        >
                          Sign up
                        </Link>
                      </li>
                    </ul>
                  )}
                  {!!user && (
                    <ul className="list-reset header-nav-right">
                      <li>
                        <Button
                          size={"sm"}
                          color={"primary"}
                          onClick={logout}
                          loading={loading}
                        >
                          Logout
                        </Button>
                      </li>
                    </ul>
                  )}
                </div>
              </nav>
            </React.Fragment>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
