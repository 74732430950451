import React from 'react';
import PaymentsChild from '../components/sections/PaymentsChild';
import PaymentProvider from '../providers/PaymentProvider';

const Payments = () => {
    return (
        <PaymentProvider>
            <PaymentsChild hasBgColor />
        </PaymentProvider>
    );
};

export default Payments;