import React from "react";
import ActivitiesProvider from "../providers/ActivitiesProvider";
import ActivitiesGrids from "../components/sections/ActivityGrids";
import Cta from "../components/sections/Cta";

const Activities = () => {
  return (
    <ActivitiesProvider>
      <ActivitiesGrids className="illustration-section-04" />
      <Cta hasBgColor invertColor split className="illustration-element-08" />
    </ActivitiesProvider>
  );
};

export default Activities;
