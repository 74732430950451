import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { HomeContext } from "../../providers/HomeProvider";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = (props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...rest
  } = props;

  const { home } = React.useContext(HomeContext);

  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "ABOUT US",
    paragraph:
      "Social Activities - Cultural Activities - Social Awareness Program - Community Hall Services",
  };

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-top"
          >
            <div className="features-split-icon mb-24">
              <Image
                src={require("./../../assets/images/features-split-icon.svg")}
                alt="Features split icon"
                width={80}
                height={80}
              />
            </div>
          </SectionHeader>
          <div className={splitClasses}>
            {home.sections.map((section, index) =>
              index % 2 === 0 ? (
                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">{section.title}</h3>
                    <p className="m-0">
                      {section.description}
                    </p>
                  </div>
                  <div
                    className={classNames(
                      "split-item-image center-content-mobile illustration-element-03",
                      imageFill && "split-item-image-fill"
                    )}
                  >
                    <Image
                      src={require("./../../assets/images/features-split-image.svg")}
                      alt="Features split 01"
                      width={528}
                      height={396}
                    />
                    <div style={imgStyle}>
                      <Image
                        src={section.image}
                        alt="Features split top 01"
                        width={738}
                        height={570}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">{section.title}</h3>
                    <p className="m-0">
                      {section.description}
                    </p>
                  </div>
                  <div
                    className={classNames(
                      "split-item-image center-content-mobile illustration-element-04",
                      imageFill && "split-item-image-fill"
                    )}
                  >
                    <Image
                      src={require("./../../assets/images/features-split-image.svg")}
                      alt="Features split 02"
                      width={528}
                      height={396}
                    />
                    <div style={imgStyle}>
                      <Image
                        src={section.image}
                        alt="Features split top 02"
                        width={744}
                        height={512}
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const imgStyle = {
  position: "absolute",
  width: "100%",
  maxWidth: "100%",
  top: "0",
  left: "0",
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
