import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import { AboutContext } from "../../providers/AboutProvider";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const HeroFull = (props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...rest
  } = props;

  const { about } = React.useContext(AboutContext);

  const [state, setState] = React.useState({
    videoModalActive: false,
  });

  const openModal = (e) => {
    e.preventDefault();
    setState({ videoModalActive: true });
  };

  const closeModal = (e) => {
    e.preventDefault();
    setState({ videoModalActive: false });
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="hero-content">
            <div className="container-xs">
              <h1 className="mt-0 reveal-from-top">
                About Us
              </h1>
            </div>
          </div>
          <div
            className="hero-figure illustration-element-02 reveal-from-top"
            data-reveal-delay="200"
          >
            <a
              data-video={about.video}
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require("./../../assets/images/video-placeholder.svg")}
                alt="Video"
                width={712}
                height={400}
              />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={state.videoModalActive}
            handleClose={closeModal}
            video={about.video}
            videoTag="iframe"
          />
        </div>
      </div>
    </section>
  );
};

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;
