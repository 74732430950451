import React from "react";
import { ActivityContext } from "../../providers/ActivityProvider";
import Image from "../elements/Image";
import GenericSection from "./GenericSection";

const ActivityDetails = () => {
  const { activity } = React.useContext(ActivityContext);

  return (
    <GenericSection>
      <div className="container-xs">
        <h2 className="mt-0">{activity.title}</h2>
        <p>{activity.description}</p>
        <figure>
          <Image
            className="image-larger"
            src={activity.image}
            alt="Placeholder"
            width={712}
            height={400}
          />
          <figcaption className="text-color-low">
            A super-nice image{" "}
            <span role="img" aria-label="smile">
              😀
            </span>
          </figcaption>
        </figure>
      </div>
    </GenericSection>
  );
};

export default ActivityDetails;
