import React from "react";
import Button from "../components/elements/Button";
import Input from "../components/elements/Input";
import GenericSection from "../components/sections/GenericSection";
import Modal from "../components/elements/Modal";
import PaymentProvider, { PaymentContext } from "../providers/PaymentProvider";
import Image from "../components/elements/Image";

const EditPaymentsChild = () => {
  const { page, updatePage, loading } = React.useContext(PaymentContext);

  const [description, setDescription] = React.useState("");

  React.useEffect(() => {
    setDescription(page.description);
  }, [page]);

  return (
    <GenericSection>
      <div className="container-xs">
        <h1>Update Payment Info</h1>
        <Input
          type="textarea"
          label="Description"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className="p-24 center-content">
          <Button
            loading={loading}
            onClick={() => updatePage(description)}
            color={"primary"}
          >
            Save
          </Button>
        </div>
      </div>
    </GenericSection>
  );
};

const Links = () => {
  const { paymentLinks, addPaymentLink, deletePaymentLink, loading } =
    React.useContext(PaymentContext);

  const [link, setLink] = React.useState({
    title: "",
    description: "",
    link: "",
  });

  const [modal, setModal] = React.useState(false);

  const openModal = () => {
    setModal(true);
    setLink({
      title: "",
      description: "",
      link: "",
    });
  };

  const handleChange = (e) => {
    setLink({ ...link, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addPaymentLink(link);
    setModal(false);
  };

  return (
    <GenericSection>
      <div className="container-sm">
        <h1>Payment Links</h1>
        <div className="p-24 center-content">
          <Button loading={loading} onClick={openModal} color={"primary"}>
            Add Link
          </Button>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Link</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paymentLinks.map((l) => (
                <tr key={l.id}>
                  <td>{l.title}</td>
                  <td>{l.description}</td>
                  <td>{l.link}</td>
                  <td>
                    <Button
                      color="primary"
                      onClick={() => deletePaymentLink(l.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Modal show={modal} handleClose={() => setModal(false)}>
          <div className="center-content">
            <div className="features-split-icon mb-16">
              <Image
                src={require("../assets/images/logo.jpeg")}
                alt="Frame icon"
                width={64}
                height={64}
              />
            </div>
            <h3 className="mt-0 mb-12">Add Payment Link</h3>
          </div>
          <Input
            type="text"
            label="Title"
            name="title"
            placeholder="Title"
            formGroup="desktop"
            value={link.title}
            onChange={handleChange}
          />
          <Input
            type="text"
            label="Description"
            name="description"
            placeholder="Description"
            formGroup="desktop"
            value={link.description}
            onChange={handleChange}
          />
          <Input
            type="text"
            label="Link"
            name="link"
            placeholder="Link"
            formGroup="desktop"
            value={link.link}
            onChange={handleChange}
          />
          <div className="mt-16 mb-16">
            <Button
              color="primary"
              wide
              loading={loading}
              onClick={handleSubmit}
            >
              Add Link
            </Button>
          </div>
        </Modal>
      </div>
    </GenericSection>
  );
};

const EditPayments = () => {
  return (
    <PaymentProvider>
      <EditPaymentsChild />
      <Links />
    </PaymentProvider>
  );
};

export default EditPayments;
