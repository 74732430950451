import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { ActivitiesContext } from "../../providers/ActivitiesProvider";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const ActivityGrids = (props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...rest
  } = props;

  const { activities } = React.useContext(ActivitiesContext);

  const outerClasses = classNames(
    "team section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "team-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Activities",
    paragraph: "Social & Cultural Activities",
  };

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {activities.map((member, index) => (
              <div className="tiles-item">
                <div key={index} className="tiles-item-inner">
                  <Link key={index} to={`/activities/${member.id}`}>
                    <div className="team-item-header">
                      <div className="team-item-image mb-24 illustration-element-05">
                        <Image
                          src={member.image}
                          alt="Team member 01"
                          width={190}
                          height={190}
                        />
                      </div>
                    </div>
                    <div className="team-item-content">
                      <h5 className="team-item-name mt-0 mb-4">
                        {member.title}
                      </h5>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

ActivityGrids.propTypes = propTypes;
ActivityGrids.defaultProps = defaultProps;

export default ActivityGrids;
