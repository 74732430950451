import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { ContactContext } from "../../providers/ContactProvider";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const ContactInfo = (props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...rest
  } = props;

  const outerClasses = classNames(
    "features-tiles section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Contact Information",
    paragraph: "Stay connected!",
  };

  const { contactInfo } = React.useContext(ContactContext);

  return (
    <section {...rest} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16 reveal-from-top"
                    data-reveal-container=".tiles-item"
                  >
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-01.svg")}
                      alt="Features tile icon 01"
                      width={72}
                      height={72}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4
                    className="h5 mt-0 mb-8 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="100"
                  >
                    Address
                  </h4>
                  {contactInfo.address.split("\n").map((line, index) => (
                    <p className="m-0 mb-16 text-xs" key={index}>{line}</p>
                  ))}
                </div>
              </div>
            </div>

            <div className="tiles-item">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="100"
                  >
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-02.svg")}
                      alt="Features tile icon 02"
                      width={72}
                      height={72}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4
                    className="h5 mt-0 mb-8 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="200"
                  >
                    Phone No.
                  </h4>
                  {contactInfo.phoneNumbers.split("\n").map((line, index) => (
                    <p className="m-0 mb-16 text-xs" key={index}>{line}</p>
                  ))}
                </div>
              </div>
            </div>

            <div className="tiles-item">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="200"
                  >
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-03.svg")}
                      alt="Features tile icon 03"
                      width={72}
                      height={72}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4
                    className="h5 mt-0 mb-8 reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="300"
                  >
                    E-Mail Us
                  </h4>
                  {contactInfo.emailIds.split("\n").map((line, index) => (
                    <p className="m-0 mb-16 text-xs" key={index}>{line}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContactInfo.propTypes = propTypes;
ContactInfo.defaultProps = defaultProps;

export default ContactInfo;
