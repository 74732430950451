import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import React from "react";
import { auth } from "../config/firebase";

export const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [user, setUser] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [initializing, setInitializing] = React.useState(true);

  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      setInitializing(false);
    });
  }, []);

  const login = (email, password) => {
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => setUser(result.user))
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  };

  const logout = () => {
    setLoading(true);
    signOut(auth)
      .then(() => setUser(null))
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  };

  const resolve = () => setError(null);

  const value = {
    user,
    loading,
    error,
    login,
    logout,
    resolve,
  };

  return (
    <AuthContext.Provider value={value}>
      {!initializing && props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
