import React from "react";
import { NoticeContext } from "../../providers/NoticeProvider";
import Button from "../elements/Button";
import Image from "../elements/Image";
import GenericSection from "./GenericSection";

const NoticeDetails = () => {
  const { notice } = React.useContext(NoticeContext);

  return (
    <GenericSection>
      <div className="container-xs">
        <h2 className="mt-0">{notice.subject}</h2>
        <p>{notice.description}</p>
        {!!notice.attachment && (
          <div className="button-group pb-32">
            <Button
              color={"secondary"}
              wide
              onClick={() => window.open(notice.attachment, "_blank")}
            >
              View Attachment
            </Button>
          </div>
        )}
        <p>Expire On: {notice.expireOn?.toDate()?.toLocaleDateString()}</p>
        <p>Created On: {notice.createdOn?.toDate()?.toLocaleDateString()}</p>
      </div>
    </GenericSection>
  );
};

export default NoticeDetails;
