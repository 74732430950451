import React from "react";
import Button from "../components/elements/Button";
import Input from "../components/elements/Input";
import GenericSection from "../components/sections/GenericSection";
import ContactProvider, { ContactContext } from "../providers/ContactProvider";

const EditContactChild = () => {
  const [contact, setContact] = React.useState({
    address: "",
    phoneNumbers: "",
    emailIds: "",
  });

  const { contactInfo, updateContactInfo, loading } =
    React.useContext(ContactContext);

  React.useEffect(() => {
    setContact(contactInfo);
  }, [contactInfo]);

  const handleChange = (event) => {
    event.preventDefault();
    setContact({
      ...contact,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <GenericSection>
      <div className="container-xs">
        <h1>Update Contact Info</h1>
        <Input
          type="textarea"
          label="Address"
          name="address"
          value={contact.address}
          onChange={handleChange}
        />
        <Input
          type="textarea"
          label="Phone Numbers"
          name="phoneNumbers"
          value={contact.phoneNumbers}
          onChange={handleChange}
        />
        <Input
          type="textarea"
          label="Email Ids"
          name="emailIds"
          value={contact.emailIds}
          onChange={handleChange}
        />
        <div className="p-24 center-content">
          <Button
            loading={loading}
            onClick={() => updateContactInfo(contact)}
            color={"primary"}
          >
            Save
          </Button>
        </div>
      </div>
    </GenericSection>
  );
};

const EditContact = () => {
  return <ContactProvider>
      <EditContactChild />
  </ContactProvider>;
};

export default EditContact;
