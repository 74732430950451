import { doc, getDoc, setDoc } from "firebase/firestore/lite";
import React from "react";
import { db } from "../config/firebase";

export const HomeContext = React.createContext();

const HomeProvider = (props) => {
  const [home, setHome] = React.useState({
    headline: "",
    description: "",
    leadImage: "",
    sections: [],
    gallery: [],
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const homeRef = doc(db, "home", "gkiYMMkLTmmWmmGEx6g2");
    getDoc(homeRef).then((doc) => {
      setHome(doc.data());
    });
  }, []);

  /**
   *
   * @param {{
   * headline: string,
   * description: string,
   * leadImage: string,
   * sections: {title: string, description: string, image: string}[],
   * gallery: string[],
   * }} home
   */
  const updateHome = (home) => {
    const homeRef = doc(db, "home", "gkiYMMkLTmmWmmGEx6g2");
    setLoading(true);
    setDoc(homeRef, home)
      .then(() => setHome(home))
      .finally(() => setLoading(false));
  };

  const value = {
    home,
    updateHome,
    loading,
  };

  return (
    <HomeContext.Provider value={value}>{props.children}</HomeContext.Provider>
  );
};

export default HomeProvider;
