import React from "react";
import Cta from "../components/sections/Cta";
import NoticeDetails from "../components/sections/NoticeDetails";
import NoticeProvider from "../providers/NoticeProvider";

const Notice = (props) => {
  const { id } = props.match.params;

  return (
    <NoticeProvider id={id}>
      <NoticeDetails />
      <Cta hasBgColor invertColor split className="illustration-element-08" />
    </NoticeProvider>
  );
};

export default Notice;
