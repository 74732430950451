import React from "react";
import { Link } from "react-router-dom";
import GenericSection from "../components/sections/GenericSection";

const Admin = () => {
  return (
    <GenericSection>
      <div className="container-xs">
        <h1>Choose to Edit</h1>
        <ul>
          <li>
            <Link to="/edit-home">Home</Link>
          </li>
          <li>
            <Link to="/edit-about">About</Link>
          </li>
          <li>
            <Link to="/edit-membership">Membership</Link>
          </li>
          <li>
            <Link to="/edit-payments">Payments</Link>
          </li>
          <li>
            <Link to="/edit-activities">Activities</Link>
          </li>
          <li>
            <Link to="/edit-notices">Notice Board</Link>
          </li>
          <li>
            <Link to="/edit-contact">Contact</Link>
          </li>
        </ul>
      </div>
    </GenericSection>
  );
};

export default Admin;
