import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import Button from "../elements/Button";
import PropTypes from "prop-types";
import { savePhoto } from "../../utils/firebase";

const propTypes = {
  ...SectionTilesProps.types,
  gallery: PropTypes.array,
  setGallery: PropTypes.func,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class UpdateGallery extends React.Component {
    state = {
        loading: false
    };

    handleAdd = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.hidden = true;

        input.onchange = async (e) => {
            const file = e.target.files[0];
            this.setState({ loading: true });
            const url = await savePhoto(file);
            this.props.setGallery([...this.props.gallery, url]);
            this.setState({ loading: false });
        };

        input.click();
    };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      gallery,
      setGallery,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "team section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "team-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "Update Gallery",
      paragraph: "Add or remove images (max. 5)",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              {gallery.map((imageSrc, index) => (
                <div key={index} className="tiles-item">
                  <div className="tiles-item-inner">
                    <div className="team-item-header">
                      <div className="team-item-image mb-16">
                        <Image
                          src={imageSrc}
                          alt="Features tile icon 01"
                          width={128}
                          height={128}
                        />
                      </div>
                    </div>
                    <div className="team-item-content">
                      <h6
                        className="team-item-name mt-0 mb-4"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setGallery([...gallery.filter((g) => g !== imageSrc)])
                        }
                      >
                        Remove
                      </h6>
                    </div>
                  </div>
                </div>
              ))}

              {gallery.length < 5 && (
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-content">
                      <Button
                        color={"primary"}
                        size={"sm"}
                        onClick={this.handleAdd}
                        loading={this.state.loading}
                      >
                        Add Image
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

UpdateGallery.propTypes = propTypes;
UpdateGallery.defaultProps = defaultProps;

export default UpdateGallery;
