import React from "react";
import { db } from "../config/firebase";
import {
  doc,
  addDoc,
  getDoc,
  updateDoc,
  getDocs,
  deleteDoc,
  query,
  collection,
} from "firebase/firestore/lite";

export const PaymentContext = React.createContext();

const PaymentProvider = ({ children }) => {
  const [page, setPage] = React.useState({
    description: "",
  });

  const [paymentLinks, setPaymentLinks] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    loadPage();
    loadPaymentLinks();
  }, []);

  const loadPage = () => {
    const ref = doc(db, "paymentPage", "paymentPage");
    getDoc(ref).then((snap) => {
      setPage(snap.data());
    });
  };

  const loadPaymentLinks = async () => {
    const ref = collection(db, "paymentPage", "paymentPage", "links");
    return getDocs(query(ref)).then((snap) => {
      setPaymentLinks(
        snap.docs.map((docsnap) => ({ ...docsnap.data(), id: docsnap.id }))
      );
    });
  };

  const updatePage = (description) => {
    setLoading(true);
    const ref = doc(db, "paymentPage", "paymentPage");
    updateDoc(ref, { description }).then(() => {
      setPage({ description });
      setLoading(false);
    });
  };

  const addPaymentLink = (title, description, link) => {
    setLoading(true);
    const ref = collection(db, "paymentPage", "paymentPage", "links");
    addDoc(ref, { title, description, link })
      .then(() => loadPaymentLinks())
      .finally(() => {
        setLoading(false);
      });
  };

  const deletePaymentLink = (id) => {
    setLoading(true);
    const ref = doc(db, "paymentPage", "paymentPage", "links", id);
    deleteDoc(ref)
      .then(() => {
        setPaymentLinks(paymentLinks.filter((link) => link.id !== id));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const value = {
    page,
    paymentLinks,
    updatePage,
    addPaymentLink,
    deletePaymentLink,
    loading,
  };

  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  );
};

export default PaymentProvider;
